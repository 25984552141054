import { useNavigateAsync, useOptionalActivePlannerId, useServices, useViewModel } from '@/hooks';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Dialog } from '../../utils';
import { SchoolDetailsEdit } from './SchoolDetailsEdit';

export interface CreateSchoolDialogProps {
  isOpen: boolean;
  onClose?: (schoolId: string | undefined) => void;
}

export const CreateSchoolDialog = observer(({ isOpen, onClose }: CreateSchoolDialogProps) => {
  const { localization, route } = useServices();
  const plannerId = useOptionalActivePlannerId();
  const strings = localization.localizedStrings.school;

  const navigate = useNavigateAsync();

  function close(schoolId: string | undefined, attachError: Error | undefined) {
    if (onClose != null) {
      onClose(schoolId);
      return Promise.resolve();
    }

    if (schoolId != null && schoolId.length > 0 && plannerId != null) {
      const newSchoolLocation = route.resolvePlannerSettingsLocation('schools', plannerId);
      const state = { hasAttachErrorSchoolId: attachError != null ? schoolId : undefined };
      return navigate(newSchoolLocation, { replace: true, state });
    } else {
      return navigate(-1);
    }
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createCreateSchoolDialogViewModel(plannerId, close),
    [plannerId]
  );

  const title = useMemo(() => {
    return strings.createSchoolTitle;
  }, []);

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={title}
      width="sm"
      renderData={() => (
        <SchoolDetailsEdit
          viewModel={viewModel}
          sx={{
            width: '100%'
          }}
        />
      )}
    />
  );
});
