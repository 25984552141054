import { Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface SchoolSettingsDangerActionProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle: string;
  buttonIcon?: ReactNode;
  buttonTitle: string;
  action: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  error?: string;
}

export const SchoolSettingsDangerAction = observer(
  ({
    sx = [],
    className,
    title,
    subtitle,
    buttonIcon,
    buttonTitle,
    isLoading,
    action,
    disabled,
    error
  }: SchoolSettingsDangerActionProps) => {
    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Stack
          spacing={2}
          direction={{ xs: 'column', xl: 'row' }}
          sx={{
            alignItems: { xl: 'center' }
          }}
        >
          <Stack
            sx={{
              flex: 1
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: '500'
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            >
              {subtitle}
            </Typography>
          </Stack>

          <Button
            onClick={() => action()}
            variant="contained-grey"
            color="error"
            startIcon={buttonIcon}
            sx={{ minWidth: 150, maxWidth: 250 }}
            disabled={disabled}
            loading={isLoading}
          >
            {buttonTitle}
          </Button>
        </Stack>
        {error && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: '500',
              color: (theme) => theme.palette.error.main
            }}
          >
            {error}
          </Typography>
        )}
      </Stack>
    );
  }
);
