import { CloseRounded, InfoOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Tooltip, Typography, alpha, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useActivePlannerId, useServices } from '../../hooks';
import { RefreshButton, getBlurredClassName } from '../utils';

export type UserDashboardBannerActionClickHandler = (() => void) | (() => Promise<void>);

export type UserDashboardBannerAction =
  | { case: 'text'; value: string; onClick: UserDashboardBannerActionClickHandler; tooltip?: string }
  | { case: 'icon'; value: ReactNode; onClick: UserDashboardBannerActionClickHandler; tooltip?: string }
  | {
      case: 'icon-text';
      value: { text: string; icon: ReactNode };
      onClick: UserDashboardBannerActionClickHandler;
      tooltip?: string;
    };

export interface UserDashboardBannerProps {
  sx?: SxProps;
  className?: string;
  identifier: string;
  isVisible: boolean;
  title: string;
  color?: 'info' | 'warning' | 'error' | 'success' | 'default';
  canDismiss?: boolean;
  // If false, the dismiss state is not stored in the LocalStorage. Defaults to true.
  canDismissPermanently?: boolean;
  message?: string;
  icon?: (color: string) => ReactNode;
  actions?: UserDashboardBannerAction[];
}

export const UserDashboardBanner = observer(
  ({
    sx = [],
    className,
    identifier,
    canDismiss = true,
    canDismissPermanently = true,
    isVisible,
    icon = (color) => <InfoOutlined sx={{ color }} />,
    actions = [],
    message = '',
    title,
    color = 'default'
  }: UserDashboardBannerProps) => {
    const { settingsStorage } = useServices();
    const theme = useTheme();
    const plannerId = useActivePlannerId();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    // Hiding by default until the stored settings has been fetched
    const [isBannerDismissed, setIsBannerDismissed] = useState(true);

    useEffect(() => {
      const fetchSetting = async () => {
        const isDismissed = await settingsStorage.userDashboardBannerIsDismissed(plannerId, identifier);
        setIsBannerDismissed(isDismissed ?? false);
      };

      void fetchSetting();
    }, [plannerId, identifier]);

    const [backgroundColor, textColor] = useMemo(() => {
      switch (color) {
        case 'info':
          return [theme.palette.info.main, theme.palette.info.contrastText];
        case 'error':
          return [theme.palette.error.main, theme.palette.error.contrastText];
        case 'warning':
          return [theme.palette.warning.main, theme.palette.warning.contrastText];
        case 'success':
          return [theme.palette.success.main, theme.palette.success.contrastText];
        case 'default':
          return [theme.palette.primary.main, theme.palette.primary.contrastText];
      }
    }, [color]);

    if (!isVisible || isBannerDismissed) {
      return null;
    }

    const hasMessage = message.length > 0;

    function dismissBanner() {
      if (!canDismiss) {
        return;
      }

      setIsBannerDismissed(true);

      if (canDismissPermanently) {
        settingsStorage.setUserDashboardBannerIsDismissed(true, plannerId, identifier);
      }
    }

    const isBlurred = settingsStorage.backgroundImage != null && settingsStorage.reduceTransparency !== true;

    return (
      <Box
        sx={{
          ...sx,
          px: 2,
          py: 0.5,
          borderRadius: 1,
          color: textColor,
          backgroundColor: !isBlurred ? backgroundColor : undefined
        }}
        className={clsx(className, isBlurred && getBlurredClassName('paper', theme, backgroundColor))}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{
            alignItems: { xs: 'justify', sm: hasMessage ? 'flex-start' : 'center' }
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              flex: 1,
              alignItems: 'center'
            }}
          >
            {icon('inherit')}

            <Stack
              sx={{
                flex: 1
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: 'inherit'
                }}
              >
                {title}
              </Typography>

              {hasMessage && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: alpha(textColor, 0.6)
                  }}
                >
                  {message}
                </Typography>
              )}
            </Stack>

            {isExtraSmallScreen && canDismiss && (
              <IconButton size="medium" color="inherit" onClick={dismissBanner}>
                <CloseRounded fontSize="small" />
              </IconButton>
            )}
          </Stack>

          {(actions.length > 0 || (!isExtraSmallScreen && canDismiss)) && (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              {actions.map((a, i) => (
                <Action key={`action-${i}`} action={a} />
              ))}

              {!isExtraSmallScreen && canDismiss && (
                <IconButton size="medium" color="inherit" onClick={dismissBanner}>
                  <CloseRounded fontSize="small" />
                </IconButton>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    );
  }
);

interface ActionProps {
  action: UserDashboardBannerAction;
}
function Action({ action }: ActionProps) {
  const [isProcessing, setIsProcessing] = useState(false);

  async function onClick() {
    setIsProcessing(true);
    await action.onClick();
    setIsProcessing(false);
  }

  switch (action.case) {
    case 'text':
    case 'icon-text': {
      const text = action.case === 'text' ? action.value : action.value.text;
      const icon = action.case === 'icon-text' ? action.value.icon : undefined;

      return (
        <Tooltip title={action.tooltip} disableInteractive>
          <Button color="inherit" loading={isProcessing} onClick={() => void onClick()} startIcon={icon}>
            {text}
          </Button>
        </Tooltip>
      );
    }

    case 'icon':
      return (
        <Tooltip title={action.tooltip} disableInteractive>
          <RefreshButton isRefreshing={isProcessing} reloadData={() => void onClick()} />
        </Tooltip>
      );
  }
}
