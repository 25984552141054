import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { captureException } from '@sentry/react';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import {
  AppBaseStaticDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';
import { SchoolEditViewModel } from './SchoolEditViewModel';

export interface CreateSchoolDialogViewModel extends StaticDialogViewModel, SchoolEditViewModel {}

export class AppCreateSchoolDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements CreateSchoolDialogViewModel
{
  @observable private _isApplying = false;
  @observable private _editSchoolError: string | undefined;
  @observable private _name = '';
  @observable private _subtitle = '';

  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string | undefined,
    private readonly _close:
      | ((schoolId: string | undefined, attachError: Error | undefined) => Promise<void>)
      | undefined,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(() => _close?.(undefined, undefined) ?? new Promise((resolve) => resolve()));
    makeObservable(this);

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.apply());
    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfig.title = () => this._localization.localizedStrings.school.createSchoolSaveButtonLabel;
  }

  readonly isReadOnly = false;

  readonly hasChanges = false;

  @computed
  get isSubmitting(): boolean {
    return this._isApplying;
  }

  @computed
  get canApply(): boolean {
    const canApplyChanges = this._name.length > 0;
    return !this.isApplying && canApplyChanges;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isApplying;
    this._saveButtonConfig.isEnabled = this.canApply;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get editSchoolError(): string | undefined {
    return this._editSchoolError;
  }

  @computed
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @computed
  get subtitle(): string {
    return this._subtitle;
  }

  set subtitle(value: string) {
    this._subtitle = value;
  }

  @action
  clearCreateSchoolError() {
    this._editSchoolError = undefined;
  }

  async apply() {
    if (this.name.length === 0) {
      return;
    }

    runInAction(() => {
      this._isApplying = true;
      this._editSchoolError = undefined;
    });

    try {
      const { schoolId, attachError } = await this._userStore.createSchool(
        this.name,
        this.subtitle,
        SchoolSharingMode.SHARED,
        true,
        this._plannerId
      );
      await this._close?.(schoolId, attachError);
    } catch (e) {
      captureException(e);
      const error = e as Error;
      runInAction(() => (this._editSchoolError = error.message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  resetChanges() {
    // Nothing to reset
  }
}
