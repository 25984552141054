import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';

export interface SideBarSectionProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  title?: string;
  isCompact: boolean;
  itemsSpacing?: number;
  action?: { icon: (sx: SxProps) => ReactNode; onClick: () => void; tooltip?: string };
}

export function SideBarSection({
  sx = [],
  className,
  children,
  title,
  isCompact,
  action,
  itemsSpacing
}: SideBarSectionProps) {
  return (
    <Stack className={className} sx={sx} spacing="2px">
      {(title != null || (action != null && !isCompact)) && (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {title != null && (
            <Typography
              color="textSecondary"
              variant="subtitle2"
              sx={{ flex: 1, fontWeight: '600', fontSize: 13, px: 0.5 }}
            >
              {title}
            </Typography>
          )}

          {action != null && !isCompact && (
            <Tooltip title={action.tooltip} disableInteractive placement="right" arrow>
              <IconButton size="small" onClick={() => action?.onClick()}>
                {action.icon({ fontSize: 'small' })}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
      <Stack spacing={itemsSpacing}>{children}</Stack>
    </Stack>
  );
}
