import { getOptionalDashConcatenation } from '@/models';
import { PlannerSideBarCourseSectionItemViewModel } from '@/viewmodels';
import { ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { ColorIndicator } from '../../lists';

export interface MainScreenPlannerSideBarSchoolCourseItemProps {
  sx?: SxProps;
  className?: string;
  course: PlannerSideBarCourseSectionItemViewModel;
  plannerId: string;
  onSelect?: () => void;
}

export const MainScreenPlannerSideBarSchoolCourseItem = observer(
  ({ sx = [], className, course, plannerId, onSelect }: MainScreenPlannerSideBarSchoolCourseItemProps) => {
    const { route } = useServices();
    const strings = LocalizedStrings.sidebar.school;

    const theme = useTheme();
    const params = useParams();
    const activeCourseSectionId: string = params.courseSectionId ?? '';
    const activeCourseId: string = params.courseId ?? '';
    const isActive = course.id === activeCourseSectionId || course.schoolCourseSectionId === activeCourseId;

    const secondaryTooltipText = course.isDemo
      ? strings.demoCourseSubtitle()
      : strings.courseSubtitle(course.studentUsingTodayCount, course.totalStudentCount);

    return (
      <Tooltip
        title={
          <span>
            {getOptionalDashConcatenation(course.courseTitle, course.section)}
            <br />
            {secondaryTooltipText}
          </span>
        }
        placement="right"
        arrow
      >
        <ListItemButton
          disableGutters
          sx={{
            ...sx,
            borderRadius: 1,
            px: 0.5,
            display: 'flex',
            alignItems: 'stretch',
            [':hover']: {
              backgroundColor: !isActive ? theme.palette.action.focus : 'transparent'
            }
          }}
          className={className}
          component={Link}
          to={route.resolveSchoolCourseSectionDetailsLocation(plannerId, course.schoolId, course.schoolCourseSectionId)}
          onClick={!isActive ? onSelect : undefined}
          dense
        >
          <ColorIndicator sx={{ mr: 1, my: '2px' }} color={course.color} />

          <ListItemText
            sx={{ my: '2px' }}
            primary={course.courseTitle}
            secondary={course.section}
            slotProps={{
              primary: { noWrap: true },
              secondary: { noWrap: true, variant: 'caption' }
            }}
          />
        </ListItemButton>
      </Tooltip>
    );
  }
);
