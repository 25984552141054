import { useIsMatchPath } from '@/hooks';
import {
  Badge,
  Box,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Tooltip,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactElement, Ref } from 'react';
import { Link, PathPattern } from 'react-router';

export interface SideBarButtonBadgeOptions {
  readonly label: string;
  readonly color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  readonly icon?: ReactElement;
}

export interface SideBarButtonProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  link?: { to: string; mathPattern?: PathPattern };
  onSelect?: () => void;
  title: string;
  titleCanWrap?: boolean;
  secondary?: string;
  secondaryCanWrap?: boolean;
  icon?: ReactElement;
  iconKind?: 'icon' | 'avatar';
  tooltip?: string;
  disabled?: boolean;
  badge?: SideBarButtonBadgeOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: Ref<any>;
}

export const SideBarButton = observer(
  ({
    sx = [],
    className,
    isCompact,
    link,
    onSelect,
    title,
    secondary,
    icon,
    iconKind = 'icon',
    tooltip,
    disabled,
    titleCanWrap = true,
    secondaryCanWrap = true,
    badge,
    ref
  }: SideBarButtonProps) => {
    const theme = useTheme();
    const matchesLink = useIsMatchPath(link?.mathPattern);

    const content = (
      <>
        {isCompact && (
          <Badge badgeContent={badge?.label} color={badge?.color}>
            <Box
              sx={{
                display: 'flex',
                color: matchesLink
                  ? theme.palette.primary.main
                  : theme.palette.mode === 'light'
                    ? theme.palette.text.secondary
                    : undefined
              }}
            >
              {icon}
            </Box>
          </Badge>
        )}

        {!isCompact && (
          <>
            {icon != null &&
              (iconKind === 'icon' ? (
                <ListItemIcon sx={{ color: matchesLink ? theme.palette.primary.main : undefined }}>{icon}</ListItemIcon>
              ) : (
                <ListItemAvatar
                  sx={{
                    minWidth: 0,
                    marginRight: 1.5
                  }}
                >
                  {icon}
                </ListItemAvatar>
              ))}
            <ListItemText
              primary={title}
              secondary={secondary}
              slotProps={{
                primary: {
                  color: matchesLink ? 'primary' : undefined,
                  noWrap: !titleCanWrap,
                  fontWeight: matchesLink ? 600 : undefined
                },
                secondary: { color: matchesLink ? 'primary' : undefined, noWrap: !secondaryCanWrap }
              }}
            />

            {renderBadge(badge)}
          </>
        )}
      </>
    );

    const mainStyle: SxProps = {
      ...sx,
      justifyContent: isCompact ? 'center' : undefined,
      borderRadius: isCompact ? '50%' : 1,
      flex: 'unset',
      backgroundColor: matchesLink ? theme.palette.action.hover : undefined,
      py: isCompact ? 0.5 : 0,
      pl: isCompact ? 0.5 : 1,
      pr: isCompact || badge != null ? 0.5 : undefined,
      display: 'flex',

      [':hover']: {
        backgroundColor: theme.palette.action.focus
      }
    };

    const itemButton =
      link != null ? (
        <ListItemButton
          ref={ref}
          component={Link}
          to={link.to}
          className={className}
          sx={mainStyle}
          onClick={onSelect}
          dense
          disabled={disabled}
        >
          {content}
        </ListItemButton>
      ) : (
        <ListItemButton ref={ref} className={className} sx={mainStyle} onClick={onSelect} dense disabled={disabled}>
          {content}
        </ListItemButton>
      );

    return (
      <Tooltip
        title={tooltip ?? title}
        placement="right"
        disableFocusListener={!isCompact}
        disableHoverListener={!isCompact}
        disableTouchListener={!isCompact}
        arrow
      >
        {itemButton}
      </Tooltip>
    );
  }
);
function renderBadge(options: SideBarButtonBadgeOptions | undefined) {
  if (options == null) {
    return null;
  }

  return (
    <Chip
      label={options.label}
      variant="filled"
      size="small"
      icon={options.icon}
      color={options.color}
      sx={{ cursor: 'pointer' }}
    />
  );
}
