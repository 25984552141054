import { OnboardingClassroomConnectionStepViewModel } from '@/viewmodels';
import { SkipNextRounded } from '@mui/icons-material';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { isSxArray } from '../../utils';
import { ConnectedAppConnectButton, ConnectedAppIcon } from '../connected-apps';

export interface OnboardingClassroomConnectionStepProps {
  sx?: SxProps;
  className?: string;
  viewModel: OnboardingClassroomConnectionStepViewModel;
}

export const OnboardingClassroomConnectionStep = observer(
  ({ sx = [], className, viewModel }: OnboardingClassroomConnectionStepProps) => {
    const { connectedApp, hasSchool } = viewModel;
    const [isConnecting, setIsConnecting] = useState(false);
    const [connectError, setConnectError] = useState<Error | undefined>();
    const strings = LocalizedStrings.onboarding.classroom;

    async function connect() {
      setIsConnecting(true);
      setConnectError(undefined);

      try {
        await viewModel.connect();
      } catch (e) {
        if (e !== 'popup_closed') {
          setConnectError(e as Error);
          captureException(e);
        }
      } finally {
        setIsConnecting(false);
      }
    }

    return (
      <Box
        className={className}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
          },
          ...(isSxArray(sx) ? sx : [sx])
        ]}
      >
        <Stack sx={{ maxWidth: 450 }} spacing={3}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center'
            }}
          >
            <ConnectedAppIcon kind="classroom" width={44} style={{ flexShrink: 0 }} />

            <Stack spacing={0.5}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '500'
                }}
              >
                {strings.title()}
              </Typography>

              <Typography variant="body2">{strings.subtitle()}</Typography>
            </Stack>
          </Stack>

          <Typography
            sx={{
              flex: 1,
              whiteSpace: 'pre-wrap'
            }}
          >
            {hasSchool ? strings.messageSchool() : strings.messagePlanner()}
          </Typography>

          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              alignSelf: 'center'
            }}
          >
            {connectedApp.hasSyncStatus &&
              !connectedApp.hasSyncError &&
              (isConnecting ? (
                <Button variant="contained" disabled loading sx={{ width: 180 }}>
                  &nbsp;
                </Button>
              ) : (
                <ConnectedAppConnectButton kind="classroom" onClick={() => void connect()} />
              ))}

            {!connectedApp.hasSyncStatus &&
              (connectedApp.hasSyncError ? (
                <Stack
                  spacing={2}
                  sx={{
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      fontWeight: '500'
                    }}
                  >
                    {strings.syncStatusFetchErrorMessage()}
                  </Typography>

                  <Button
                    variant="contained-grey"
                    startIcon={<RetryIcon />}
                    onClick={() => void connectedApp.refreshSyncStatus()}
                  >
                    {strings.retryFetchSyncStatusButtonLabel()}
                  </Button>
                </Stack>
              ) : (
                <CircularProgress size={30} />
              ))}

            {connectError != null && (
              <Typography
                variant="caption"
                sx={{
                  color: (theme) => theme.palette.error.main,
                  fontWeight: '500',
                  textAlign: 'center'
                }}
              >
                {strings.connectErrorMessage()}
              </Typography>
            )}
          </Stack>

          <Box>
            <Divider variant="middle" />
          </Box>

          <Stack
            spacing={2}
            sx={{
              alignItems: 'center'
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.text.secondary,
                textAlign: 'center'
              }}
            >
              {strings.skipMessage()}
            </Typography>

            <Button size="small" endIcon={<SkipNextRounded />} type="submit" disabled={isConnecting}>
              {strings.skipButtonLabel()}
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  }
);
