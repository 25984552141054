import { getOptionalDashConcatenation } from '@/models';
import { PlannerSideBarCourseSectionItemViewModel } from '@/viewmodels';
import { ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router';
import { useServices } from '../../../hooks';
import { ColorIndicator } from '../../lists';

export interface MainScreenPlannerSideBarCourseProps {
  sx?: SxProps;
  className?: string;
  course: PlannerSideBarCourseSectionItemViewModel;
  isCompact: boolean;
  close: () => void;
}

export const MainScreenPlannerSideBarCourse = observer(
  ({ sx = [], course, className, close, isCompact }: MainScreenPlannerSideBarCourseProps) => {
    const { route } = useServices();
    const theme = useTheme();

    const params = useParams();
    const activeCourseSectionId: string = params.courseSectionId ?? '';
    const isActive = course.id === activeCourseSectionId;

    return (
      <Tooltip title={getOptionalDashConcatenation(course.courseTitle, course.section)} placement="right" arrow>
        <ListItemButton
          sx={{
            ...sx,
            borderRadius: 1,
            px: 0.5,
            display: 'flex',
            alignItems: 'stretch',
            backgroundColor: isActive ? theme.palette.action.hover : undefined,

            [':hover']: {
              backgroundColor: theme.palette.action.focus
            }
          }}
          className={className}
          component={Link}
          to={route.resolvePlannerCourseSectionDetailLocation(course.plannerId, course.id)}
          onClick={close}
          dense
        >
          <ColorIndicator sx={{ mr: isCompact ? 0.5 : 1, my: '2px' }} color={course.color} />
          <ListItemText
            sx={{ flex: 1, my: '2px' }}
            primary={course.courseTitle}
            secondary={course.section}
            slotProps={{
              primary: { color: isActive ? 'primary' : undefined, noWrap: true },
              secondary: { color: isActive ? 'primary' : undefined, noWrap: true, variant: 'caption' }
            }}
          />
        </ListItemButton>
      </Tooltip>
    );
  }
);
