import { UserDataStore } from '@/stores';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SubscriptionInactiveReason } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/subscription_inactive_reason_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { UserProfile } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_profile_pb';
import { setUser } from '@sentry/react';
import { addDays, endOfDay, getUnixTime } from 'date-fns';
import { chain } from 'lodash';
import { autorun, reaction } from 'mobx';
import { AllConnectedAppKinds, ConnectedAppKind } from '../../models';
import {
  AnalyticsService,
  AuthenticationService,
  ConnectedAppsService,
  FirebaseContract,
  IntercomData,
  IntercomService,
  LocalizationService,
  MonitoringService,
  UserService
} from '../contracts';

export class AppMonitoringService implements MonitoringService {
  constructor(
    private readonly _intercom: IntercomService,
    firebase: FirebaseContract,
    private readonly _user: UserService,
    private readonly _connectedApps: ConnectedAppsService,
    private readonly _analytics: AnalyticsService,
    authentication: AuthenticationService,
    private readonly _userStore: UserDataStore,
    private readonly _localization: LocalizationService
  ) {
    reaction(
      () => [authentication.isInitialized, _user.currentUser],
      () => {
        const user = _user.currentUser;

        if (!authentication.isInitialized) {
          return;
        }

        void this.updateIntercomUser(user);

        if (user != null) {
          // Update sentry user
          setUser({
            id: user.userId,
            email: user.emailAddress
          });

          // Update firebase user
          const firebaseData: Record<string, string> = {};
          firebaseData.classroom_role = this.getPersonaStringValue(user.persona);
          firebase.setUser(user.userId, firebaseData);
        } else {
          // Resetting users in libs
          setUser(null);
          firebase.resetUser();
        }
      }
    );

    // Log ad conversion events
    autorun(() => {
      const user = _user.currentUser;
      if (user != null && user.persona === UserPersona.UNSPECIFIED) {
        void this._analytics.logAdConversionOnce('signup');
      }
    });

    autorun(() => {
      if (this._userStore.subscription.hasData) {
        const subscription = this._userStore.subscription.data;
        if (subscription != null) {
          if (subscription.subscription.trialDaysRemaining >= 0) {
            void this._analytics.logAdConversionOnce('trial-started');
          } else if (subscription.subscription.isActive) {
            void this._analytics.logAdConversionOnce('paid-subscription');
          }
        }
      }
    });

    // We're also responsible for refreshing data when we get focus back.

    // PASCAL: I am disabling this temporarily, because we are seeing abnormal spikes
    //         in per-user-per-minute API calls which are causing us to bust our quotas.
    //         I can't say for sure that this is the culprit, but I want to see if it helps.
    // window.document.addEventListener('visibilitychange', this.onVisibilityChanged.bind(this));
  }

  onLocationChange() {
    // We don't track pages or modals in Intercom, but we must update as often as possible.
    // This will fetch any inbound message as well.
    const user = this._user.currentUser;

    if (user != null) {
      void this._intercom.update({});
    }
  }

  private calculateInvitationStatus(planners: Planner[], relationship: PlannerRelationshipKind): string {
    const counts = {
      shared: 0,
      invited: 0,
      absent: 0
    };

    planners.forEach((p) => {
      if (p.relationships.findIndex((r) => r.kind === relationship) != -1) {
        counts.shared++;
      } else if (p.sharingInvitations.findIndex((i) => i.receiverRelationshipKind === relationship) != -1) {
        counts.invited++;
      } else {
        counts.absent++;
      }
    });

    const keys = Object.keys(counts);

    return (
      keys
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
        .filter((key) => (counts as any)[key] > 0)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
        .map((key) => `${key} (${(counts as any)[key]})`)
        .join(',')
    );
  }

  /*
  onVisibilityChanged() {
    // If returning to the app after at least 15 seconds, refresh.
    // Why 15 seconds? Long enough to not refresh after just browsing through tabs or
    // accidentally tabbing out. Short enough to refresh after making a change to an assignment
    // within Classroom (probably opened from Today).
    const now = imMoment();
    if (window.document.hidden) {
      this._lastHidden = now;
    } else if (
      this._lastHidden != null &&
      this._mainStore.hasData &&
      !this._mainStore.isRefreshing &&
      this._mainStore.lastUpdate!.isBefore(this._lastHidden) &&
      now.diff(this._lastHidden, 'second') >= 15
    ) {
      // Not awaited.
      this._mainStore.refresh();
    }
  }
  */

  private getDomainForEmailAddress(email: string): string {
    const index = email.indexOf('@');

    if (index === -1) {
      return '';
    }

    return email.slice(index + 1);
  }

  private getPersonaStringValue(persona: UserPersona): string {
    switch (persona) {
      case UserPersona.STUDENT:
        return 'student';
      case UserPersona.TEACHER:
        return 'teacher';
      case UserPersona.PARENT:
        return 'parent';
      case UserPersona.OTHER:
        return 'other';
      default:
        return '';
    }
  }

  private getSubscriptionStatusValueForReason(reason: SubscriptionInactiveReason) {
    switch (reason) {
      case SubscriptionInactiveReason.CANCELED:
        return 'canceled';
      case SubscriptionInactiveReason.PAST_DUE:
        return 'past-due';
      case SubscriptionInactiveReason.UNSPECIFIED:
        return 'unspecified';
    }
  }

  private getConnectedConnectedAppKindsForPlanners(planners: Planner[]): ConnectedAppKind[] {
    const kinds = new Set<ConnectedAppKind>();

    planners.forEach((p) => {
      const services = AllConnectedAppKinds.map((kind) =>
        this._connectedApps.getConnectedAppService(kind, p.id, undefined)
      );

      p.schoolIds.forEach((id) =>
        services.push(...AllConnectedAppKinds.map((kind) => this._connectedApps.getConnectedAppService(kind, p.id, id)))
      );

      chain(services)
        .compact()
        .filter((s) => s.isConnected)
        .forEach((s) => kinds.add(s.kind));
    });

    return Array.from(kinds);
  }

  private async updateIntercomUser(user: UserProfile | undefined) {
    await this._intercom.boot(user);
    if (user != null) {
      this.updateIntercomUserData(user);
    }
  }

  private updateIntercomUserData(user: UserProfile) {
    const intercomData: IntercomData = {
      email: user.emailAddress,
      name: user.fullName,
      language_override: this._localization.currentLocale,
      created_at: user.createdTime != null ? getUnixTime(user.createdTime.toDate()) : undefined,
      avatar: {
        type: 'avatar',
        image_url: user.pictureUrl
      },
      email_domain: this.getDomainForEmailAddress(user.emailAddress),
      persona: this.getPersonaStringValue(user.persona),
      is_anonymous: false
      // The company attribute is left blank for now, but email_domain can be used
      // to identify users' schools, until we support a real notion of school.
    };

    // Only if we have data do we provide the rest.
    if (this._userStore.plannersLoadable.hasData) {
      const planners = this._userStore.planners;
      intercomData.planner_ids = planners.map((p) => p.id).join(',');
      intercomData.connected_apps = this.getConnectedConnectedAppKindsForPlanners(planners).join(',');
      intercomData.has_school_account = this._userStore.schools.length > 0;

      intercomData.student_invitations = this.calculateInvitationStatus(planners, PlannerRelationshipKind.STUDENT);
      intercomData.parent_invitations = this.calculateInvitationStatus(planners, PlannerRelationshipKind.PARENT);
      intercomData.teacher_invitations = this.calculateInvitationStatus(planners, PlannerRelationshipKind.TEACHER);
    }
    if (this._userStore.subscription.hasData) {
      const subscription = this._userStore.subscription.data;

      if (subscription != null) {
        // The following attributes cannot be filled client-side for the moment:
        // * stripe_customer_id
        // * subscription_start_date
        // * subscription_will_cancel_at_period_end
        // * subscription_period_end_date
        // The Stripe app for Intercom uses the user's email to find the corresponding
        // customer.

        intercomData.subscription_price_id = subscription.subscription.productPriceId;
        intercomData.subscription_plan = subscription.product.title;

        // This status is not as rich as what we have access with Stripe,
        // and does not fully match what is reported to Mixpanel by the backend.
        // Words "trialing" and "active" match the Stripe values.
        intercomData.subscription_status =
          subscription.subscription.trialDaysRemaining >= 0
            ? 'trialing'
            : subscription.subscription.isActive
              ? 'active'
              : this.getSubscriptionStatusValueForReason(subscription.subscription.inactiveReason);

        intercomData.subscription_trial_end_at =
          subscription.subscription.trialDaysRemaining >= 0
            ? getUnixTime(endOfDay(addDays(new Date(), subscription.subscription.trialDaysRemaining)))
            : undefined;
      }
    }

    void this._intercom.update(intercomData);
  }
}
