import { Browser } from '@capacitor/browser';
import { Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { captureException } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router';
import LocalizedStrings from 'strings';
import { useServices } from '../../hooks';

export interface StartTrialExistingSubscriptionProps {
  sx?: SxProps;
  className?: string;
}

export const StartTrialExistingSubscription = observer(({ sx, className }: StartTrialExistingSubscriptionProps) => {
  const { localization, userStore } = useServices();
  const strings = LocalizedStrings.startTrial.existingSubscription;

  const [isFetchingManageUrl, setIsFetchingManageUrl] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  async function manageSubscription() {
    setIsFetchingManageUrl(true);
    setError(undefined);

    try {
      const url = await userStore.getManageSubscriptionUrl(window.location.href);
      await Browser.open({ url });
    } catch (e) {
      captureException(e);
      const error = e as Error;
      const strings = localization.localizedStrings.utils;
      setError(strings.manageSubscriptionErrorMessage(error.message));
    } finally {
      setIsFetchingManageUrl(false);
    }
  }

  return (
    <Stack sx={sx} className={className} spacing={3}>
      <Stack alignItems="center">
        <Typography variant="h6">{strings.title()}</Typography>
        <Typography color="textSecondary">{strings.subtitle()}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Button variant="contained" component={Link} to="/">
          {strings.goToPlanner()}
        </Button>

        <Button loading={isFetchingManageUrl} disabled={isFetchingManageUrl} onClick={() => void manageSubscription()}>
          {strings.manage()}
        </Button>

        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
});
