import { useActivePlannerId, useNavigateAsync, useServices } from '@/hooks';
import SidebarCollapseIcon from '@/resources/icons/SidebarCollapseIcon';
import { UserDashboardSideBarViewModel } from '@/viewmodels';
import { SettingsRounded } from '@mui/icons-material';
import { Box, CardActionArea, List, Stack, Theme, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { ReactNode, useRef, useState } from 'react';
import { BackgroundImageScreenPaper, MediaQuery } from '../../utils';
import { UserProfileIcon } from '../UserProfileIcon';
import { ProfileMenu } from '../profile-menu';
import { SideBarAlert } from './SideBarAlert';
import { SideBarButton } from './SideBarButton';
import './Sidebar.styles.css';

export interface SideBarProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardSideBarViewModel;
  showSettings?: boolean;
  scrollableItems: (isCompact: boolean) => ReactNode;
  staticItems?: (isCompact: boolean) => ReactNode;
  forceFullScreenWidth?: boolean;
  onClose?: () => void;
  id: string;
}

export const SideBar = observer(
  ({
    sx = [],
    className,
    forceFullScreenWidth = false,
    showSettings = true,
    scrollableItems,
    staticItems,
    viewModel,
    onClose,
    id
  }: SideBarProps) => {
    const { localization, route, settings } = useServices();
    const [fullWidth, setFullWidth] = useState(!settings.isSchoolSidebarCollapsed);
    const isSmallScreen = useMediaQuery<Theme>((th) => th.breakpoints.down('md'));
    const [showProfileMenu, setShowProfileMenu] = useState(false);

    const profileButtonRef = useRef<HTMLButtonElement | null>(null);
    const strings = localization.localizedStrings.utils;
    const theme = useTheme();

    const plannerId = useActivePlannerId();
    const navigate = useNavigateAsync();

    function collapse() {
      setFullWidth(false);
      settings.isSchoolSidebarCollapsed = true;
    }

    function expand() {
      setFullWidth(true);
      settings.isSchoolSidebarCollapsed = false;
    }

    function showConnectedApps() {
      const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
      void navigate(destination);
    }

    const isCollapsed = !fullWidth || isSmallScreen;
    const isCompact = !forceFullScreenWidth && isCollapsed;

    return (
      <BackgroundImageScreenPaper
        square
        className={clsx(
          className,
          'sidebar',
          !isCompact && !forceFullScreenWidth && 'sidebar-regular',
          isCompact && 'sidebar-compact'
        )}
        sx={{
          ...sx,
          height: '100%',
          width: forceFullScreenWidth ? '100%' : undefined,
          borderRight: settings.backgroundImage == null ? `1px solid ${theme.palette.divider}` : undefined
        }}
      >
        <Stack
          sx={{
            width: forceFullScreenWidth ? '100%' : isCollapsed ? 80 : 250,
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <List sx={{ flex: 1, pt: 1, overflow: 'auto', px: isCompact ? 0.5 : 1 }}>
            <Stack spacing={isCompact ? 1 : 1.5}>{scrollableItems(isCompact)}</Stack>
          </List>

          <Stack spacing={1} sx={{ p: 1 }}>
            {viewModel.hasConnectedAppsWarning && (
              <SideBarAlert
                severity="warning"
                isCompact={isCompact}
                message={strings.sideBarConnectedAppWarningMessage}
                action={showConnectedApps}
              />
            )}

            {viewModel.hasConnectedAppsError && (
              <SideBarAlert
                severity="error"
                isCompact={isCompact}
                message={strings.sideBarConnectedAppErrorMessage}
                action={showConnectedApps}
              />
            )}

            {staticItems != null && <Box>{staticItems(isCompact)}</Box>}

            <Stack
              direction={isCompact ? 'column' : 'row'}
              spacing={isCompact ? 1 : 0.5}
              justifyContent="flex-end"
              alignItems="center"
            >
              {!forceFullScreenWidth && (
                <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
                  <Tooltip
                    title={isCompact ? strings.sidebarExpand : strings.sidebarCollapse}
                    placement="right"
                    disableFocusListener={!isCompact}
                    disableHoverListener={!isCompact}
                    disableTouchListener={!isCompact}
                    arrow
                  >
                    <SideBarButton
                      isCompact
                      title=""
                      icon={<SidebarCollapseIcon />}
                      iconKind="icon"
                      onSelect={() => (fullWidth ? collapse() : expand())}
                    />
                  </Tooltip>
                </MediaQuery>
              )}

              {showSettings && (
                <Tooltip
                  title={strings.settingsTitle}
                  placement="right"
                  disableFocusListener={!isCompact}
                  disableHoverListener={!isCompact}
                  disableTouchListener={!isCompact}
                  arrow
                >
                  <SideBarButton
                    isCompact
                    onSelect={() => onClose?.()}
                    title={''}
                    link={{
                      to: route.resolvePlannerSettingsLocation('edit', plannerId),
                      mathPattern: {
                        path: route.resolvePlannerSettingsLocation('', plannerId),
                        end: false
                      }
                    }}
                    icon={<SettingsRounded />}
                    iconKind="icon"
                  />
                </Tooltip>
              )}

              <Box sx={{ pt: isCompact ? 0.5 : 0, pl: !isCompact ? 0.5 : 0 }}>
                <Tooltip
                  title={strings.appBarAccountTooltip}
                  placement="right"
                  disableFocusListener={!isCompact}
                  disableHoverListener={!isCompact}
                  disableTouchListener={!isCompact}
                  arrow
                >
                  <CardActionArea
                    ref={profileButtonRef}
                    sx={{ p: 0.5, borderRadius: '50%', flex: 'none', display: 'flex', width: 'unset' }}
                    onClick={() => setShowProfileMenu(true)}
                  >
                    <UserProfileIcon
                      url={viewModel.imageUrl}
                      userFullName={viewModel.fullName}
                      emotionalStatusEmoji={viewModel.userEmotionalStatusEmoji}
                    />
                  </CardActionArea>
                </Tooltip>
              </Box>
            </Stack>
          </Stack>
        </Stack>

        <ProfileMenu
          id={id}
          isOpen={showProfileMenu}
          onClose={(actionPressed) => {
            setShowProfileMenu(false);
            if (actionPressed) {
              onClose?.();
            }
          }}
          anchorEl={profileButtonRef.current}
        />
      </BackgroundImageScreenPaper>
    );
  }
);
