import { useServices } from '@/hooks';
import { PlannerParticipationRequestViewModel } from '@/viewmodels';
import { Button, Card, ListItem, ListItemText, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { ConfirmationDialog } from '../../utils';

export interface PlannerParticipationRequestListItemProps {
  request: PlannerParticipationRequestViewModel;
  sx?: SxProps;
  className?: string;
  dismiss: () => void;
}

export const PlannerParticipationRequestListItem = observer(
  ({ request, sx = [], className, dismiss }: PlannerParticipationRequestListItemProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.participationRequests;

    const [requestAcknowledgementState, setRequestAcknowledgementState] = useState<boolean | undefined>();
    const [showDenyConfirmAlert, setShowDenyConfirmAlert] = useState(false);

    async function denyRequest(hasConfirmed: boolean) {
      setShowDenyConfirmAlert(false);

      if (hasConfirmed) {
        setRequestAcknowledgementState(false);
        const hasPendingRequests = await request.acknowledgeRequest(false);

        if (hasPendingRequests) {
          setRequestAcknowledgementState(undefined);
        } else {
          dismiss();
        }
      }
    }

    async function acceptRequest() {
      setRequestAcknowledgementState(true);
      const hasPendingRequests = await request.acknowledgeRequest(true);

      if (hasPendingRequests) {
        setRequestAcknowledgementState(undefined);
      } else {
        dismiss();
      }
    }

    return (
      <>
        <Card sx={{ ...sx, p: 2 }} className={className}>
          <Stack direction="column" spacing={2}>
            <ListItem disableGutters disablePadding>
              <ListItemText
                sx={{ m: 0 }}
                primary={request.requesterName}
                secondary={strings.requestedOnDateLabel(request.requestTime)}
              />
            </ListItem>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: 'flex'
              }}
            >
              <Button
                sx={{ flex: 1 }}
                variant="contained-grey"
                color="error"
                onClick={() => setShowDenyConfirmAlert(true)}
                loading={requestAcknowledgementState === false}
                disabled={requestAcknowledgementState != null}
              >
                {strings.denyButtonLabel}
              </Button>
              <Button
                sx={{ flex: 1 }}
                variant="contained"
                color="primary"
                onClick={() => void acceptRequest()}
                loading={requestAcknowledgementState === true}
                disabled={requestAcknowledgementState != null}
              >
                {strings.grantButtonLabel}
              </Button>
            </Stack>
          </Stack>
        </Card>
        {showDenyConfirmAlert && (
          <ConfirmationDialog
            isOpen={true}
            message={strings.denyConfirmationMessage(request.requesterName)}
            confirmButtonLabel={strings.denyConfirmationButtonLabel}
            isDestructive
            onSubmit={(hasConfirmed) => void denyRequest(hasConfirmed)}
          />
        )}
      </>
    );
  }
);
