import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import {
  EnglishSharingInvitationCodeStrings,
  FrenchSharingInvitationCodeStrings
} from './SharingInvitationCodeStrings';

export interface ExUtilsStrings {
  readonly aboutContactUsButtonTitle: string;
  readonly aboutContactUsChatButtonTitle: string;
  readonly aboutContactUsHelperText: string;
  readonly aboutContactUsTitle: string;
  readonly aboutMessage: string;
  readonly aboutVersionNumberTitle: string;
  readonly anonymousUserWarningMessage: string;
  readonly anonymousUserWarningTitle: string;
  readonly appBarAccountTooltip: string;
  readonly appBarActivePlanner: string;
  readonly appBarCreateWorkButtonTitle: string;
  readonly appBarRefreshPlannerDataTooltip: string;
  readonly appBarRefreshSchoolDataTooltip: string;
  readonly appBarTitle: string;
  readonly asyncConfirmationDialogErrorMessage: string;
  readonly changeActiveDashboardOptionTitle: string;
  readonly changeEmotionalStatusOptionTitle: string;
  readonly confirmationDialogCancelButton: string;
  readonly confirmationDialogConfirmButton: string;
  readonly confirmationDialogTitle: string;
  readonly connectedAppsError: string;
  readonly connectedAppsLabel: string;
  readonly copyToClipboard: string;
  readonly courseSectionList: string;
  readonly createDashboardMenuLabel: string;
  readonly createDashboardMenuPlannerLabel: string;
  readonly createDashboardMenuSchoolLabel: string;
  readonly createDashboardMenuSubtitle: string;
  readonly createPlannerMenuLabel: string;
  readonly createScheduleCycleBannerActionLabel: string;
  readonly createScheduleCycleBannerLabel: string;
  readonly credentialsAlreadyLinkedCancelButton: string;
  readonly credentialsAlreadyLinkedConnectButton: string;
  readonly credentialsAlreadyLinkedWarningMessage: string;
  readonly credentialsAlreadyLinkedWarningTitle: string;
  readonly datePickerInvalidMessage: string;
  readonly dateTimePickerCancelLabel: string;
  readonly dateTimePickerOkLabel: string;
  readonly demoMode: string;
  readonly demoModeDisableConfirmationButtonLabel: string;
  readonly demoModeDisableConfirmationMessage: string;
  readonly demoModeEnableConfirmationButtonLabel: string;
  readonly demoModeEnableConfirmationMessage: string;
  readonly dialogButtonCancelTitle: string;
  readonly dialogButtonCloseTitle: string;
  readonly dialogButtonEditTitle: string;
  readonly dialogButtonSaveTitle: string;
  readonly dialogFullScreenButtonTooltip: string;
  readonly dialogMoreActionsTooltip: string;
  readonly dialogReduceSizeButtonTooltip: string;
  readonly editSchoolDetails: string;
  readonly emotionalSurveyCardTitle: string;
  readonly emotionalSurveyDismissTooltip: string;
  readonly emotionalSurveySubmitButtonTitle: string;
  readonly errorContactUs: string;
  readonly errorContent: string;
  readonly errorDefaultMessage: string;
  readonly errorDetailsMessage: string;
  readonly errorRefreshLabel: string;
  readonly errorRetryLabel: string;
  readonly errorTitle: string;
  readonly genericCancel: string;
  readonly genericSave: string;
  readonly invite: string;
  readonly inviteStudents: string;
  readonly legendButtonDefaultTitle: string;
  readonly loadingDataLabel: string;
  readonly loadingPlannerErrorGoHomeLabel: string;
  readonly loadingPlannerErrorMessage: string;
  readonly loadingPlannerErrorRetryLabel: string;
  readonly loadingPlannerLabel: string;
  readonly loginLabel: string;
  readonly logoutLabel: string;
  readonly manageAccountLabel: string;
  manageSubscriptionErrorMessage(error: string): string;
  readonly manageSubscriptionLabel: string;
  readonly nonAuthenticatedUserMessage: string;
  readonly notFoundConnectedAs: (name: string) => string;
  readonly notFoundLogIn: string;
  readonly notFoundNavigateToDashboard: string;
  readonly notFoundText: string;
  readonly notFoundTitle: string;
  readonly openIntercom: string;
  readonly participationRequestsCountFullLabel: (count: number) => string;
  readonly participationRequestsCountLabel: (count: number) => string;
  readonly participationRequestsLabel: string;
  readonly preferencesLabel: string;
  readonly profileMenuTitle: string;
  readonly refreshLabel: string;
  readonly renamePlannerLabel: string;
  readonly searchBarDefaultPlaceholder: string;
  readonly sendSupportEmail: string;
  readonly serverErrorContactUs: string;
  readonly serverErrorContent: string;
  readonly serverErrorRetryButtonLabel: string;
  readonly serverErrorTitle: string;
  readonly settingsSharedAboutTitle: string;
  readonly settingsSharedAccountManagementTitle: string;
  readonly settingsSharedPreferencesTitle: string;
  readonly settingsSharedProfileEditTitle: string;
  readonly settingsSharedSectionTitle: string;
  readonly settingsTitle: string;
  sharePlannerLabel(relationship: PlannerRelationshipKind): string;
  readonly shareSchoolBannerLearnMore: string;
  readonly shareSchoolBannerTitle: string;
  readonly sidebarCollapse: string;
  readonly sideBarConnectedAppErrorButtonTitle: string;
  readonly sideBarConnectedAppErrorMessage: string;
  readonly sideBarConnectedAppErrorTitle: string;
  readonly sideBarConnectedAppWarningButtonTitle: string;
  readonly sideBarConnectedAppWarningMessage: string;
  readonly sideBarConnectedAppWarningTitle: string;
  readonly sidebarExpand: string;
  readonly signOut: string;
  readonly splashSwitchToDarkModeTooltip: string;
  readonly splashSwitchToLightModeTooltip: string;
  readonly studentLastEmotionalStatusDateTooltip: (statusEmoji: string) => string;
  readonly studentLastEmotionalStatusNoneTooltip: string;
  readonly studentPlannerBannerLabel: string;
  readonly studentPlannerPickerNoOwnerEmail: string;
  readonly studentPlannerPickerNoOwnerName: string;
  readonly studentPlannerPickerNoResult: string;
  readonly studentPlannerPickerSearchBarPlaceholder: string;
  readonly studentPlannerPickerTitle: string;
  readonly subscribeLabel: string;
  readonly summaryListShowAllButton: string;
  readonly tableNoResultsLabel: (searchText: string) => string;
  readonly tablePageSizePickerLabel: string;
  readonly timePickerInvalidMessage: string;
  readonly tutorialsLabel: string;
  readonly tutorialsLink: string;
  readonly unsplash: string;
  readonly unsplashPictureBy: string;
  readonly unsplashPictureOn: string;
  readonly updatableDialogDismissConfirmationCancelButton: string;
  readonly updatableDialogDismissConfirmationDiscardButton: string;
  readonly updatableDialogDismissConfirmationMessageButton: string;
  updateActivePlannerErrorMessage(error: string): string;
  readonly useSharingInvitationCodeLabel: string;
  readonly userDashboardPickerOpenStudentPlanner: string;
  readonly userDashboardPickerOtherDashboards: string;
  readonly userDashboardPickerPlannerSubtitle: string;
  readonly userDashboardPickerSchoolSubtitle: string;
  readonly userDashboardPickerTitle: string;
}

export const EnglishUtilsStrings: ExUtilsStrings = {
  aboutContactUsButtonTitle: 'Contact us',
  aboutContactUsChatButtonTitle: 'Start a live chat with support',
  aboutContactUsHelperText:
    'You can always use the help bubble at bottom-right of the screen to start chatting with support.',
  aboutContactUsTitle: 'You have a question?',
  aboutMessage:
    'To learn more about Studyo Go, visit our <a href="https://studyo.co/en/school-planner/studyo-go" target="_blank" rel="noreferrer" class="inline-link">website</a>.',
  aboutVersionNumberTitle: 'Version number',
  anonymousUserWarningMessage:
    'You are using Studyo Go with a temporary account. Your data lives only on this device. Create an account to preserve your data and access it from all your devices.',
  anonymousUserWarningTitle: 'Temporary account',
  appBarAccountTooltip: 'Your account',
  appBarActivePlanner: 'Active planner',
  appBarCreateWorkButtonTitle: 'Create an item',
  appBarRefreshPlannerDataTooltip: 'Reload my items',
  appBarRefreshSchoolDataTooltip: 'Synchronize',
  appBarTitle: 'Studyo Go',
  asyncConfirmationDialogErrorMessage: 'An error occurred. Please try again later.',
  changeActiveDashboardOptionTitle: 'Change current planner',
  changeEmotionalStatusOptionTitle: 'Change my mood',
  confirmationDialogCancelButton: 'Cancel',
  confirmationDialogConfirmButton: 'OK',
  confirmationDialogTitle: 'Confirmation',
  connectedAppsError: 'At least one of the connected apps requires your attention',
  connectedAppsLabel: 'Connected apps',
  copyToClipboard: 'Copy to clipboard',
  courseSectionList: 'Classes',
  createDashboardMenuLabel: 'Create…',
  createDashboardMenuPlannerLabel: 'Planner',
  createDashboardMenuSchoolLabel: 'School',
  createDashboardMenuSubtitle: 'What would you like to create?',
  createPlannerMenuLabel: 'Create new planner',
  createScheduleCycleBannerActionLabel: 'Configure schedule',
  createScheduleCycleBannerLabel: 'You do not have any schedules configured',
  credentialsAlreadyLinkedCancelButton: 'Cancel',
  credentialsAlreadyLinkedConnectButton: 'Switch Account',
  credentialsAlreadyLinkedWarningMessage:
    'A user with a planner already exists for this account. You can force the switch, but you will loose the current planner.',
  credentialsAlreadyLinkedWarningTitle: 'Account already exists',
  datePickerInvalidMessage: 'Invalid date format',
  dateTimePickerCancelLabel: 'Cancel',
  dateTimePickerOkLabel: 'Ok',
  demoMode: 'Demo mode',
  demoModeDisableConfirmationButtonLabel: 'Disable',
  demoModeDisableConfirmationMessage: 'Are you sure you wish to disable the demo mode?',
  demoModeEnableConfirmationButtonLabel: 'Enable',
  demoModeEnableConfirmationMessage: 'Are you sure you wish to enable the demo mode?',
  dialogButtonCancelTitle: 'Cancel',
  dialogButtonCloseTitle: 'Close',
  dialogButtonEditTitle: 'Edit',
  dialogButtonSaveTitle: 'Save',
  dialogFullScreenButtonTooltip: 'Display full screen (ctrl + shift + F)',
  dialogMoreActionsTooltip: 'More actions',
  dialogReduceSizeButtonTooltip: 'Decrease size (ctrl + shift + F)',
  editSchoolDetails: 'Edit school details',
  emotionalSurveyCardTitle: "I'm feeling…",
  emotionalSurveyDismissTooltip: 'Dismiss until tomorrow',
  emotionalSurveySubmitButtonTitle: 'Save',
  errorContactUs: 'contact us',
  errorContent:
    'An error occurred.\nThe Studyo Go team has been informed of the problem.\nIf you have more details to share about the issue,\nplease ',
  errorDefaultMessage: 'An error occurred',
  errorDetailsMessage: 'I had an unexpected error and have more details to provide.\n\n',
  errorRefreshLabel: 'Refresh',
  errorRetryLabel: 'Retry',
  errorTitle: 'Oops…',
  genericCancel: 'Cancel',
  genericSave: 'Save',
  invite: 'Sharing…',
  inviteStudents: 'Invite students',
  legendButtonDefaultTitle: 'Legend',
  loadingDataLabel: 'Loading…',
  loadingPlannerErrorGoHomeLabel: 'Go home',
  loadingPlannerErrorMessage: 'An error occurred while loading the planner. Make sure you have access to it.',
  loadingPlannerErrorRetryLabel: 'Retry',
  loadingPlannerLabel: 'Loading planner details…',
  loginLabel: 'Create an account',
  logoutLabel: 'Sign out',
  manageAccountLabel: 'Modify my profile',
  manageSubscriptionErrorMessage: (error) =>
    `An error occurred while opening the subscription management portal. Details: ${error}`,
  manageSubscriptionLabel: 'Manage subscription',
  nonAuthenticatedUserMessage: 'Please sign in to benefit from the full Studyo Go experience!',
  notFoundConnectedAs: (name) => `Connected as: ${name}`,
  notFoundLogIn: 'Log in',
  notFoundNavigateToDashboard: 'Go to home page',
  notFoundText: "The requested page was either not found or you don't have the rights to access it.",
  notFoundTitle: 'Page not found',
  openIntercom: 'Start a live chat with support',
  participationRequestsCountFullLabel: (count) => `${count} request${count > 1 ? 's' : ''} waiting from teacher`,
  participationRequestsCountLabel: (count) => `${count} waiting`,
  participationRequestsLabel: 'Teacher requests',
  preferencesLabel: 'Preferences',
  profileMenuTitle: 'My account',
  refreshLabel: 'Refresh',
  renamePlannerLabel: 'Rename planner',
  searchBarDefaultPlaceholder: 'Search…',
  sendSupportEmail: 'Contact us by email',
  serverErrorContactUs: 'contact support',
  serverErrorContent: 'Something went wrong while trying to reach the servers. \nPlease try again later or ',
  serverErrorRetryButtonLabel: 'Retry',
  serverErrorTitle: 'Server unreachable',
  settingsSharedAboutTitle: 'About',
  settingsSharedAccountManagementTitle: 'Delete account',
  settingsSharedPreferencesTitle: 'Preferences',
  settingsSharedProfileEditTitle: 'Profile',
  settingsSharedSectionTitle: 'Global settings',
  settingsTitle: 'Settings',
  sharePlannerLabel: (rel) => EnglishSharingInvitationCodeStrings.sharePlannerTitle(rel),
  shareSchoolBannerLearnMore: 'Learn more',
  shareSchoolBannerTitle: 'Sharing your school with other teachers is now possible!',
  sidebarCollapse: 'Collapse',
  sideBarConnectedAppErrorButtonTitle: 'Show error',
  sideBarConnectedAppErrorMessage: 'One or more of your connected apps has an error.',
  sideBarConnectedAppErrorTitle: 'Error with connected apps',
  sideBarConnectedAppWarningButtonTitle: 'Show warning',
  sideBarConnectedAppWarningMessage: 'One or more of your connected apps has a warning.',
  sideBarConnectedAppWarningTitle: 'Warning in connected apps',
  sidebarExpand: 'Expand',
  signOut: 'Sign out',
  splashSwitchToDarkModeTooltip: 'Turn the lights off!',
  splashSwitchToLightModeTooltip: 'Turn the lights on!',
  studentLastEmotionalStatusDateTooltip: (statusEmoji) => `Student last reported feeling ${statusEmoji}`,
  studentLastEmotionalStatusNoneTooltip: 'No data available for how this student is feeling.',
  studentPlannerBannerLabel: "Viewing a student's planner. Click this banner to go back to your planner.",
  studentPlannerPickerNoOwnerEmail: 'No email address',
  studentPlannerPickerNoOwnerName: "Unknown user's planner",
  studentPlannerPickerNoResult: 'No results',
  studentPlannerPickerSearchBarPlaceholder: 'Search for a student…',
  studentPlannerPickerTitle: "Open a student's planner",
  subscribeLabel: 'Subscribe',
  summaryListShowAllButton: 'Show more',
  tableNoResultsLabel: (searchText) => `No results for "${searchText}".`,
  tablePageSizePickerLabel: 'Number of items per page:',
  timePickerInvalidMessage: 'Invalid time format',
  tutorialsLabel: 'Tutorials',
  tutorialsLink: 'https://www.youtube.com/playlist?list=PLsI1oKcvSMrsaZ58F8LlayFrMGNTeJSLU',
  unsplash: 'Unsplash',
  unsplashPictureBy: 'Photo by ',
  unsplashPictureOn: ' on ',
  updatableDialogDismissConfirmationCancelButton: 'No',
  updatableDialogDismissConfirmationDiscardButton: 'Discard changes',
  updatableDialogDismissConfirmationMessageButton: 'You have unsaved changes. Are you sure you wish to dismiss them?',
  updateActivePlannerErrorMessage: (error) => `An error occurred while updating the active planner. Details: ${error}`,
  useSharingInvitationCodeLabel: 'Use sharing code',
  userDashboardPickerOpenStudentPlanner: "Open a student's planner…",
  userDashboardPickerOtherDashboards: 'Other dashboards',
  userDashboardPickerPlannerSubtitle: 'Planner',
  userDashboardPickerSchoolSubtitle: 'School',
  userDashboardPickerTitle: 'Current planner'
};

export const FrenchUtilsStrings: ExUtilsStrings = {
  aboutContactUsButtonTitle: 'Nous contacter',
  aboutContactUsChatButtonTitle: 'Débuter un clavardage avec le support',
  aboutContactUsHelperText:
    'Vous pouvez toujours utiliser la bulle d’aide en bas à droite de la page pour clavarder avec l’équipe de support.',
  aboutContactUsTitle: 'Vous avez une question?',
  aboutMessage:
    'Pour en apprendre davantage sur Studyo Go, visitez notre <a href="https://studyo.co/fr/school-planner/studyo-go" target="_blank" rel="noreferrer" class="inline-link">site internet</a>.',
  aboutVersionNumberTitle: 'Version actuelle',
  anonymousUserWarningMessage:
    'Vous utilisez Studyo Go avec un compte temporaire. Vos données se trouvent seulement sur cet appareil. Créez un compte pour les préserver et y accéder sur tous vos appareils.',
  anonymousUserWarningTitle: 'Compte temporaire',
  // As opposed to the browser title, our inner title is localized.
  appBarAccountTooltip: 'Mon compte',
  appBarActivePlanner: 'Planificateur actif',
  appBarCreateWorkButtonTitle: 'Créer un item',
  appBarRefreshPlannerDataTooltip: 'Mettre à jour mes items',
  appBarRefreshSchoolDataTooltip: 'Synchroniser',
  appBarTitle: 'Aujourd’hui',
  asyncConfirmationDialogErrorMessage: 'Une erreur est survenue. Veuillez réessayer plus tard.',
  changeActiveDashboardOptionTitle: 'Changer le planificateur actif',
  changeEmotionalStatusOptionTitle: 'Modifier mon état d’esprit',
  confirmationDialogCancelButton: 'Annuler',
  confirmationDialogConfirmButton: 'Ok',
  confirmationDialogTitle: 'Confirmation',
  connectedAppsError: 'Au moins une application connectée requiert votre attention',
  connectedAppsLabel: 'Applications connectées',
  copyToClipboard: 'Copier dans le presse-papier',
  courseSectionList: 'Cours',
  createDashboardMenuLabel: 'Créer…',
  createDashboardMenuPlannerLabel: 'Planificateur',
  createDashboardMenuSchoolLabel: 'École',
  createDashboardMenuSubtitle: 'Que souhaitez-vous créer?',
  createPlannerMenuLabel: 'Créer un nouveau planificateur',
  createScheduleCycleBannerActionLabel: 'Configurer mon horaire',
  createScheduleCycleBannerLabel: 'Vous n’avez aucun horaire configuré.',
  credentialsAlreadyLinkedCancelButton: 'Annuler',
  credentialsAlreadyLinkedConnectButton: 'Changer de compte',
  credentialsAlreadyLinkedWarningMessage:
    'Un utilisateur avec un planificateur existe déjà pour ce compte. Vous pouvez forcer le changement de compte, mais vous perdrez le planificateur actif.',
  credentialsAlreadyLinkedWarningTitle: 'Compte existant',
  datePickerInvalidMessage: 'Format de date invalide',
  dateTimePickerCancelLabel: 'Annuler',
  dateTimePickerOkLabel: 'Ok',
  demoMode: 'Mode démo',
  demoModeDisableConfirmationButtonLabel: 'Désactiver',
  demoModeDisableConfirmationMessage: 'Êtes-vous sûr de vouloir désactiver le mode démo?',
  demoModeEnableConfirmationButtonLabel: 'Activer',
  demoModeEnableConfirmationMessage: 'Êtes-vous sûr de vouloir activer le mode démo?',
  dialogButtonCancelTitle: 'Annuler',
  dialogButtonCloseTitle: 'Fermer',
  dialogButtonEditTitle: 'Éditer',
  dialogButtonSaveTitle: 'Sauvegarder',
  dialogFullScreenButtonTooltip: 'Plein écran (ctrl + shift + F)',
  dialogMoreActionsTooltip: 'Options supplémentaires',
  dialogReduceSizeButtonTooltip: 'Réduire la taille (ctrl + shift + F)',
  editSchoolDetails: 'Modifier les détails de l’école',
  emotionalSurveyCardTitle: 'Je me sens…',
  emotionalSurveyDismissTooltip: 'Cacher jusqu’à demain',
  emotionalSurveySubmitButtonTitle: 'Enregistrer',
  errorContactUs: 'nous contacter',
  errorContent:
    'Une erreur est survenue.\nL’équipe de Studyo Go a été informée du problème.\nSi vous avez plus de détails à nous fournir,\nveuillez ',
  errorDefaultMessage: 'Une erreur est survenue',
  errorDetailsMessage: 'J’ai eu une erreur imprévue et je peux fournir plus de détails.\n\n',
  errorRefreshLabel: 'Rafraîchir',
  errorRetryLabel: 'Réessayer',
  errorTitle: 'Oups…',
  genericCancel: 'Annuler',
  genericSave: 'Sauvegarder',
  invite: 'Partager…',
  inviteStudents: 'Inviter vos élèves',
  legendButtonDefaultTitle: 'Légende',
  loadingDataLabel: 'Chargement…',
  loadingPlannerErrorGoHomeLabel: 'Aller vers la page d’accueil',
  loadingPlannerErrorMessage:
    'Une erreur est survenue en obtenant les détails du planificateur. Veuillez vous assurer que vous y avez accès.',
  loadingPlannerErrorRetryLabel: 'Réessayer',
  loadingPlannerLabel: 'Chargement du planificateur…',
  loginLabel: 'Se créer un compte',
  logoutLabel: 'Déconnexion',
  manageAccountLabel: 'Modifier mon profil',
  manageSubscriptionErrorMessage: (error) =>
    `Une erreur est survenue lors l’ouverture du portail de gestion des abonnements. Détails : ${error}`,
  manageSubscriptionLabel: 'Gérer l’abonnement',
  nonAuthenticatedUserMessage: 'Veuillez vous connecter afin de profiter de tout ce dont Studyo Go peut offrir!',
  notFoundConnectedAs: (name) => `Connecté en tant que : ${name}`,
  notFoundLogIn: 'Se connecter',
  notFoundNavigateToDashboard: 'Aller vers la page d’accueil',
  notFoundText: 'La page demandée est soit introuvable ou vous n’y avez pas accès.',
  notFoundTitle: 'Page introuvable',
  participationRequestsCountFullLabel: (count) => `${count} demande${count > 1 ? 's' : ''} d’enseignant en attente`,
  participationRequestsCountLabel: (count) => `${count} en attente`,
  participationRequestsLabel: 'Demandes d’enseignants',
  preferencesLabel: 'Préférences',
  profileMenuTitle: 'Mon compte',
  refreshLabel: 'Mettre à jour',
  renamePlannerLabel: 'Renommer le planificateur',
  searchBarDefaultPlaceholder: 'Rechercher…',
  sendSupportEmail: 'Nous contacter via courriel',
  serverErrorContactUs: 'contacter le support',
  serverErrorContent:
    'Une erreur est survenue lors de la connexion avec le serveur. \nVeuillez réessayer plus tard ou ',
  serverErrorRetryButtonLabel: 'Réessayer',
  serverErrorTitle: 'Serveur inaccessible',
  settingsSharedAboutTitle: 'À propos',
  settingsSharedAccountManagementTitle: 'Supprimer mon compte',
  settingsSharedPreferencesTitle: 'Préférences',
  settingsSharedProfileEditTitle: 'Profil',
  settingsSharedSectionTitle: 'Réglages globaux',
  settingsTitle: 'Réglages',
  sharePlannerLabel: (rel) => FrenchSharingInvitationCodeStrings.sharePlannerTitle(rel),
  shareSchoolBannerLearnMore: 'En apprendre plus',
  shareSchoolBannerTitle: 'Il est désormais possible de partager son école avec d’autres enseignants!',
  sidebarCollapse: 'Réduire',
  sideBarConnectedAppErrorButtonTitle: 'Afficher l’erreur',
  sideBarConnectedAppErrorMessage: 'Au moins une application connectée est en situation d’erreur.',
  sideBarConnectedAppErrorTitle: 'Erreur avec les applications connectées',
  sideBarConnectedAppWarningButtonTitle: 'Afficher l’alerte',
  sideBarConnectedAppWarningMessage: 'Au moins une application connectée a une alerte.',
  sideBarConnectedAppWarningTitle: 'Avertissement avec les applications connectées',
  sidebarExpand: 'Étendre',
  signOut: 'Déconnexion',
  splashSwitchToDarkModeTooltip: 'Éteindre les lumières!',
  splashSwitchToLightModeTooltip: 'Allumer les lumières!',
  studentLastEmotionalStatusDateTooltip: (statusEmoji) => `Cet élève a indiqué se sentir ${statusEmoji}`,
  studentLastEmotionalStatusNoneTooltip: 'Aucune donnée disponible pour le statut émotionnel de cet élève',
  studentPlannerBannerLabel:
    'Vous regardez le planificateur d’un élève. Cliquez cette bannière pour revenir à votre planificateur.',
  studentPlannerPickerNoOwnerEmail: 'Aucune adresse courriel',
  studentPlannerPickerNoOwnerName: 'Planificateur d’un usager inconnu',
  studentPlannerPickerNoResult: 'Aucun résultat',
  studentPlannerPickerSearchBarPlaceholder: 'Rechercher un élève…',
  studentPlannerPickerTitle: 'Ouvrir le planificateur d’un élève',
  subscribeLabel: 'S’abonner',
  summaryListShowAllButton: 'Voir plus',
  tableNoResultsLabel: (searchText) => `Aucun résultat pour "${searchText}".`,
  tablePageSizePickerLabel: 'Nombre d’items par page :',
  timePickerInvalidMessage: 'Format de temps invalide',
  tutorialsLabel: 'Tutoriels',
  tutorialsLink: 'https://www.youtube.com/playlist?list=PLsI1oKcvSMruza6pRQLEM7J2ceETLKNng',
  unsplash: 'Unsplash',
  unsplashPictureBy: 'Photo par ',
  unsplashPictureOn: ' sur ',
  updatableDialogDismissConfirmationCancelButton: 'Non',
  updatableDialogDismissConfirmationDiscardButton: 'Effacer les changements',
  updatableDialogDismissConfirmationMessageButton:
    'Vous avez des changements non-sauvegardés. Êtes-vous sûr de vouloir les rejeter?',
  updateActivePlannerErrorMessage: (error) =>
    `Une erreur est survenue lors de la sauvegarde du planificateur actif. Détails : ${error}`,
  useSharingInvitationCodeLabel: 'Utiliser un code de partage',
  userDashboardPickerOpenStudentPlanner: 'Ouvrir le planificateur d’un élève…',
  userDashboardPickerOtherDashboards: 'Autres tableaux de bord',
  userDashboardPickerPlannerSubtitle: 'Planificateur',
  userDashboardPickerSchoolSubtitle: 'École',
  userDashboardPickerTitle: 'Planificateur actif',
  openIntercom: 'Débuter un clavardage avec le support'
};
