import {
  CourseSectionInfo,
  plannerCourseSectionDetailsToInfo,
  plannerHasAccessKindsForUser,
  schoolCourseSectionToInfo,
  TimeOfDay,
  UserDashboard,
  UserDashboardInfo,
  UserDashboardPlanner,
  UserDashboardSchool
} from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { Loadable, PlannerDataStore, ScheduleCycleDataStore, SchoolDataStore, UserDataStore } from '@/stores';
import { AccessKind as PlannerAccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { computed, makeObservable } from 'mobx';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../../UpdatableViewModel';
import { getScheduleCycleKind, ScheduleCycleKind } from '../ScheduleCycleUtils';
import {
  filterPlannerCourseSectionsForActivitySchedules,
  filterSchoolCourseSectionForActivitySchedules,
  ScheduleCycleActivitySchedulesCoursesFilter
} from './ScheduleCycleActivitySchedulesCoursesFilter';
import { ScheduleCycleActivitySchedulesDayColumnInfo } from './ScheduleCycleActivitySchedulesDayColumnInfo';
import { ScheduleCyclePeriodSchedulePeriodEditViewModel } from './ScheduleCyclePeriodSchedulePeriodEditViewModel';

export interface ScheduleCyclePeriodSchedulesGridViewModel extends UpdatableViewModel {
  readonly canCreatePeriods: boolean;
  readonly canEditPeriods: boolean;
  readonly columns: ScheduleCycleActivitySchedulesDayColumnInfo[];
  readonly emptyMessage: string | undefined;

  createPeriodEditViewModel(
    columnIndex: number,
    periodId: string | undefined,
    time: TimeOfDay
  ): Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel>;
}

export abstract class BaseScheduleCyclePeriodSchedulesGridViewModel
  extends BaseUpdatableViewModel
  implements ScheduleCyclePeriodSchedulesGridViewModel
{
  protected constructor(
    protected readonly _dashboard: UserDashboardInfo,
    protected readonly _plannerId: string | undefined,
    protected readonly _scheduleCycleId: string,
    protected readonly _displayedTermId: string,
    protected readonly _filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined,
    protected readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super();
    makeObservable(this);
  }

  @computed
  protected get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  protected get courseSectionsLoadable(): Loadable<unknown> {
    if (this._plannerId != null || this._dashboard.kind === 'planner') {
      const plannerId = this._plannerId ?? this._dashboard.id;
      return this._plannerStore.getCourseSectionsInPlanner(plannerId);
    } else {
      return this._schoolStore.getCourseSections(this._dashboard.id);
    }
  }

  @computed
  protected get courseSections(): CourseSectionInfo[] {
    const hasFilters = this._filters != null;

    if (this._plannerId != null || this._dashboard.kind === 'planner') {
      const plannerId = this._plannerId ?? this._dashboard.id;
      let courses = this._plannerStore.getCourseSectionsInPlanner(plannerId).values;

      if (hasFilters) {
        courses = courses.filter((cs) => filterPlannerCourseSectionsForActivitySchedules(cs, this._filters!));
      }
      return courses.map(plannerCourseSectionDetailsToInfo);
    } else {
      let courses = this._schoolStore.getCourseSections(this._dashboard.id).values;

      if (hasFilters) {
        courses = courses.filter((cs) => filterSchoolCourseSectionForActivitySchedules(cs, this._filters!));
      }
      return courses.map(schoolCourseSectionToInfo);
    }
  }

  @computed
  protected get currentDashboard(): UserDashboard {
    switch (this._dashboard.kind) {
      case 'planner': {
        const planner = this._userStore.getPlannerForId(this._dashboard.id)!;
        return { kind: 'planner', planner } as UserDashboardPlanner;
      }

      case 'school': {
        const school = this._userStore.getSchoolForId(this._dashboard.id)!;
        return { kind: 'school', school } as UserDashboardSchool;
      }
    }
  }

  protected get loadables(): Loadable<unknown>[] {
    return [this.courseSectionsLoadable];
  }

  @computed
  protected get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  protected get scheduleCycleKind(): ScheduleCycleKind {
    return getScheduleCycleKind(this.scheduleCycle);
  }

  @computed
  private get isReadOnly(): boolean {
    const { userId } = this._userStore.user;

    switch (this.currentDashboard.kind) {
      case 'planner':
        return !plannerHasAccessKindsForUser(userId, this.currentDashboard.planner, PlannerAccessKind.FULL_ACCESS);

      case 'school':
        return !this.currentDashboard.school.administrators.some((o) => o.userId === userId);
    }
  }

  @computed
  get canCreatePeriods(): boolean {
    return !this.isReadOnly;
  }

  @computed
  get canEditPeriods(): boolean {
    return !this.isReadOnly;
  }

  abstract get columns(): ScheduleCycleActivitySchedulesDayColumnInfo[];

  abstract get emptyMessage(): string | undefined;

  abstract createPeriodEditViewModel(
    columnIndex: number,
    periodId: string | undefined,
    time: TimeOfDay
  ): Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel>;

  protected async getPeriodAndSuggestions(
    periodScheduleId: string,
    periodId: string | undefined,
    time: TimeOfDay
  ): Promise<{ period: Period; suggestions: Period[] }> {
    let period: Period;
    let suggestions: Period[] = [];

    if (periodId != null) {
      period = this.scheduleCycle.periodSchedules
        .find((s) => s.id === periodScheduleId)!
        .periods.find((p) => p.id === periodId)!;
    } else {
      const suggestedPeriod = await this._plannerStore.getSuggestedPeriods(periodScheduleId, time, this.scheduleCycle);
      period = suggestedPeriod.period;
      suggestions = suggestedPeriod.otherPeriods;
    }

    return { period, suggestions };
  }
}
